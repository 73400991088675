import React from "react";

import SensorSpecsHeader from "./SensorSpecsHeader";
import SensorSpecs from "./SensorSpecs";
import CTABanner from "components/common/footer-banners/CTABanner";

export default function SensorSpecsPage({ sensor, heroImageAltText }) {
  const {
    title,
    imageHeader,
    imageHeaderMobile,
    description,
    objectPosition,
  } = sensor;

  return (
    <>
      <SensorSpecsHeader
        title={title}
        description={description}
        webHeader={imageHeader}
        mobileHeader={imageHeaderMobile}
        objectPosition={objectPosition}
        includePricingLink={true}
        alt={heroImageAltText ?? description}
      />
      <SensorSpecs sensor={sensor} />
      <CTABanner />
    </>
  );
}
