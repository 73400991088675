import RhombusButton from "components/common/RhombusButton";
import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import { FancyTitleMed } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "linaria";
import { isArray } from "lodash";
import React from "react";

const contentContainer = css`
  display: flex;
  flex-direction: column;
  padding: 5rem 0;

  @media (max-width: 700px) {
    padding: 35px 0px;
  }
`;

const titleClass = css`
  display: inline-block;
  margin-bottom: 76px;
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 700px) {
    text-align: center;
    width: 100%;
  }
`;

const columnsContainer = css`
  display: flex;
  justify-content: space-between;
  gap: 75px;

  @media (max-width: 1000px) {
    gap: 25px;
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: unset;
  }
`;

const textColumnContainer = css`
  width: 60%;
  display: flex;
  justify-content: space-between;
  gap: 75px;

  @media (max-width: 1000px) {
    gap: 25px;
  }

  @media (max-width: 700px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: unset;
  }
`;

const columnStyle = css`
  width: 50%;
  max-width: 329px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    width: 100%;
    align-items: unset;
    max-width: 512.5px;
  }

  > div {
    width: 100%;
    margin: 0 auto;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;

const column3 = cx(
  columnStyle,
  css`
    width: 30%;

    @media (max-width: 700px) {
      width: 100%;
      max-width: 254px;
    }
  `
);

const textContainer = css`
  margin-bottom: 28px;
`;

const boldText = css`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const descriptionText = css`
  margin-bottom: 0.5rem;
`;

const dimensionsContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
`;

const imageStyle = css`
  margin-bottom: 2.5rem;
  pointer-events: none;
`;

const buttonContainer = css`
  display: flex;
  align-items: center;
  font-size: 14px;

  @media (max-width: 700px) {
    justify-content: center;
    padding: 10px 0px;
    display: none;
  }
`;

const linkStyle = css`
  justify-self: flex-end;
  align-self: center;
`;

export default function SensorSpecs({ sensor }) {
  const imageRef = getImage(sensor.image);

  return (
    <section>
      <PageContent>
        <div className={contentContainer}>
          <SlideInContainer>
            <FancyTitleMed className={titleClass}>
              {sensor.title} Sensor Specifications
            </FancyTitleMed>
          </SlideInContainer>
          <div className={columnsContainer}>
            <div className={textColumnContainer}>
              <div className={columnStyle}>
                {sensor.column1.map(item => {
                  return (
                    <SlideInContainer key={item.product}>
                      <div className={textContainer}>
                        <p className={boldText}>{item.title}</p>
                        {isArray(item.text) ? (
                          <>
                            {item.text.map(line => {
                              return (
                                <div className={descriptionText}>{line}</div>
                              );
                            })}
                          </>
                        ) : (
                          <div className={descriptionText}>{item.text}</div>
                        )}
                      </div>
                    </SlideInContainer>
                  );
                })}
              </div>
              <div className={columnStyle}>
                {!!sensor.column2 && (
                  <>
                    {sensor.column2.map(item => {
                      return (
                        <SlideInContainer key={item.title}>
                          <div className={textContainer}>
                            <p className={boldText}>{item.title}</p>
                            {isArray(item.text) ? (
                              <>
                                {item.text.map(line => {
                                  return (
                                    <div className={descriptionText}>
                                      {line}
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className={descriptionText}>{item.text}</div>
                            )}
                          </div>
                        </SlideInContainer>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className={column3}>
              <SlideInContainer>
                <div className={dimensionsContainer}>
                  <GatsbyImage
                    image={imageRef}
                    className={imageStyle}
                    alt={
                      sensor.imageAlt ??
                      `${sensor.title} sensor with dimensions`
                    }
                    style={{ maxHeight: "301px" }}
                    imgStyle={{ objectFit: "contain" }}
                  />
                  <div className={buttonContainer}>
                    <RhombusButton
                      title="Download Specs"
                      path={sensor.pdf}
                      type="primary"
                      target="_blank"
                      useHref
                    />
                  </div>
                </div>
              </SlideInContainer>
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
}
